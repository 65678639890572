import g1 from "../../../../content/project/gravity/g1.jpg";
import g2 from "../../../../content/project/gravity/g2.gif";
import g3 from "../../../../content/project/gravity/g3.gif";
import g4 from "../../../../content/project/gravity/g4.gif";
import g5 from "../../../../content/project/gravity/g5.gif";
import g6 from "../../../../content/project/gravity/g6.gif";
import g7 from "../../../../content/project/gravity/g7.gif";
import g8 from "../../../../content/project/gravity/g8.gif";
import g9 from "../../../../content/project/gravity/g9.gif";
import g10 from "../../../../content/project/gravity/g10.gif";
import * as React from 'react';
export default {
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  g10,
  React
};