import ci1 from "../../../../content/project/choregraphyinterventions/ci1.jpg";
import ci2 from "../../../../content/project/choregraphyinterventions/ci2.jpg";
import ci3 from "../../../../content/project/choregraphyinterventions/ci3.jpg";
import ci4 from "../../../../content/project/choregraphyinterventions/ci4.jpg";
import * as React from 'react';
export default {
  ci1,
  ci2,
  ci3,
  ci4,
  React
};