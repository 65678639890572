import cb1 from "../../../../content/project/clockoobird/cb1.jpg";
import cb2 from "../../../../content/project/clockoobird/cb2.jpg";
import cb3 from "../../../../content/project/clockoobird/cb3.jpg";
import cb4 from "../../../../content/project/clockoobird/cb4.jpg";
import cb5 from "../../../../content/project/clockoobird/cb5.jpg";
import cb6 from "../../../../content/project/clockoobird/cb6.jpg";
import * as React from 'react';
export default {
  cb1,
  cb2,
  cb3,
  cb4,
  cb5,
  cb6,
  React
};