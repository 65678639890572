import Setup1 from "../../../../content/project/hashtag/Setup-1.gif";
import Setup2 from "../../../../content/project/hashtag/Setup-2.gif";
import Bubble from "../../../../content/project/hashtag/Bubble.gif";
import Water from "../../../../content/project/hashtag/Water.gif";
import * as React from 'react';
export default {
  Setup1,
  Setup2,
  Bubble,
  Water,
  React
};